import React from "react"

import Tile from "@kiwicom/orbit-components/lib/Tile"
import TileGroup from "@kiwicom/orbit-components/lib/TileGroup"

import Container from "components/container"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Shift Swaps and Extra Hours">
    <Hero title="Shift Swaps and Extra Hours" />
    <Container size="medium">
      <TileGroup>
        <Tile
          title="Shift Swaps"
          description="Request a shift swap. Someone will reply to confirm the shift swap is okay."
          href="https://forms.gle/wxhqvTZuT5RMe2a76"
          external
        />
        <Tile
          title="Extra Hours"
          description="Worked extra hours? Use this form to make sure you get paid fairly."
          href="https://forms.gle/4A2ML8oXrZpzQSHCA"
          external
        />
      </TileGroup>
    </Container>
  </DriveLayout>
)

export default Page
